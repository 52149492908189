import {SortableContainer, SortableElement} from "react-sortable-hoc";
import {Grid, TextField} from "@mui/material";
import Image from "./Image";

const SortableItem = SortableElement(({value, remove}) => <Image src={value} remove={remove}/>);

const SortableList = SortableContainer(({items, remove, add}) => {
    return <Grid container direction='row' spacing={1}>
        {items.map((value, i) =>
            <SortableItem key={`item-${value}`} remove={remove} index={i} value={value}/>)
        }
        <TextField onKeyUp={e => add(e)} style={{marginTop: 'auto', marginBottom: 'auto'}} variant='standard'
                   label='Фото через запятую'/>
    </Grid>
})
export default SortableList
