import './App.css';
import Main from "./main/Main";
import {Toaster} from "react-hot-toast";

function App() {
    return [
        <Toaster key='toaster'/>,
        <Main key='main'/>
    ]
}

export default App;
