import {useState} from "react";
import SortableList from "./List";
import {arrayMove} from "react-sortable-hoc";
import toast from "react-hot-toast";
import {isEmpty, uniq} from "lodash";

const Gallery = ({startImages, setRowImages}) => {
    const [images, setImages] = useState(startImages)

    const onSortEnd = ({oldIndex, newIndex}) => {
        const newImages = arrayMove(images, oldIndex, newIndex)
        setImages(newImages)
        setRowImages(newImages)
    }
    const remove = image => {
        const newImages = [...images]
        newImages.splice(newImages.indexOf(image), 1)
        setImages(newImages)
        setRowImages(newImages)
    }
    const add = event => {
        if (event.key !== 'Enter' || event.keyCode !== 13) {
            return
        }
        event.preventDefault()
        let newImages = [...images]
        const add = event.target.value.split(',').filter(it => !isEmpty(it))
        if (add.length === 0) {
            toast.error('Пусто')
            return
        }
        newImages.push(...add)
        newImages = uniq(newImages)
        if (newImages.length === images.length) {
            toast.error('Это фото уже добавлено')
            return
        }
        setImages(newImages)
        setRowImages(newImages)
        event.target.value = ''
        toast.success('Добавлено ' + add.length + ' фото')
    }

    const cancelButtonDrag = e => e.target.nodeName === "svg" || e.target.nodeName === "path"

    return <SortableList add={add} shouldCancelStart={cancelButtonDrag} axis='x' items={images} onSortEnd={onSortEnd}
                         remove={remove}/>;
}
export default Gallery
