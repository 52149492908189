import {
    Button,
    Checkbox,
    Fab,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    Menu,
    MenuItem,
    Paper,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
    Typography
} from "@mui/material";
import FileUploader from "../file/FileUploader";
import {useState} from "react";
import {groupBy, isEmpty, sortBy, take, uniq, uniqBy} from "lodash";
import {downloadExcel, isValidHttpUrl} from "../util/Util";
import {ArrowDownward, ArrowUpward, Download, Edit} from "@mui/icons-material";
import ScrollToTop from "react-scroll-to-top";
import Gallery from "../gallery/Gallery";
import toast from "react-hot-toast";

const Main = () => {
    const [headers, setHeaders] = useState([])
    const [rows, setRows] = useState(/** @type {Row[]} */[])
    const [imageColumn, setImageColumn] = useState('')

    const [newFilename, setNewFilename] = useState('')

    const [shownColumns, setShownColumns] = useState([])

    const [uniqByImage, setUniqByImage] = useState(false)
    const [uniqImageCount, setUniqImageCount] = useState(2)
    const [rowUniqBy, setRowUniqBy] = useState([])
    const [uniqAnchor, setUniqAnchor] = useState(null)

    const [rowsPerPage, setRowsPerPage] = useState(25)
    const [page, setPage] = useState(0)

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getFilteredItems = () => {
        if (uniqByImage) {
            return uniqBy(rows, it => take(it.images, uniqImageCount).join(';'))
        }
        return (rowUniqBy.length === 0 ? rows : uniqBy(rows, it => rowUniqBy.map(id => it.cells[id]).join()))
    }

    const getPageItems = () => {
        return getFilteredItems().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }

    const closeUniq = () => setUniqAnchor(null)

    const reverseUniqSelection = id => {
        const newArr = [...rowUniqBy]
        if (newArr.includes(id)) {
            newArr.splice(newArr.indexOf(id), 1)
        } else {
            newArr.push(id)
        }
        setRowUniqBy(newArr)
    }
    const reverseColumnVisibility = name => {
        let newArr = [...shownColumns]
        if (newArr.some(it => it.name === name)) {
            newArr = newArr.filter(it => it.name !== name)
        } else {
            headers.map((it, i) => [it, i]).filter(([it]) => it === name).forEach(([_, i]) => newArr.push({
                id: i,
                name: name
            }))
        }
        setShownColumns(sortBy(newArr, 'id'))
    }

    /**
     * @param {Row} row
     * @param {string[]} images
     * @return void
     */
    const applySiblings = (row, images) => {
        if (uniqByImage) {
            const key = take(row.images, uniqImageCount).join(';')
            groupBy(rows, row => take(row.images, uniqImageCount).join(';'))[key].forEach(it => {
                it.images = images;
                it.changed = true;
            })
        }
        if (rowUniqBy.length === 0) {
            row.images = images
            row.changed = true
            return
        }
        const key = rowUniqBy.map(it => row.cells[it]).join()
        groupBy(rows, row => rowUniqBy.map(it => row.cells[it]).join())[key].forEach(it => {
            it.images = images
            it.changed = true
        })
    }

    const tryDownload = (onlyChanged) => {
        if (isEmpty(headers) || isEmpty(rows)) {
            toast.error('Товары не загружены')
            return
        }
        const writeRows = rows.filter(it => !onlyChanged || it.changed)
        if (isEmpty(writeRows)) {
            toast.error('Нет обновленных товаров')
            return
        }
        downloadExcel(headers, rows, imageColumn, newFilename, onlyChanged)
    }

    return <Grid direction='column' container padding={3} spacing={2}>
        <Tooltip title={<Typography>Только обновленные</Typography>}>
            <Fab onClick={() => tryDownload(true)} hidden={rows.length === 0} sx={{
                position: 'fixed',
                top: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(2),
                backgroundColor: 'darkcyan'
            }}>
                <Download/>
                <Edit/>
            </Fab>
        </Tooltip>
        <Tooltip title={<Typography>Все товары</Typography>}>
            <Fab color='primary' onClick={() => tryDownload(false)} hidden={rows.length === 0} sx={{
                position: 'fixed',
                top: (theme) => theme.spacing(2),
                right: (theme) => theme.spacing(11)
            }}>
                <Download/>
            </Fab>
        </Tooltip>
        <Grid item>
            <FileUploader setRows={(rows, headers, imageColumn, filename) => {
                console.log("Set headers", headers)
                setRows([])
                setHeaders(headers)
                setRows(rows)
                setImageColumn(imageColumn)
                setNewFilename(filename)
                setRowUniqBy([])
            }}/>
        </Grid>
        <Grid item>
            <Grid container direction='row' spacing={3}>
                <Grid item>
                    <FormLabel component="legend">
                        Колонка изображений:<Typography sx={{fontWeight: 'bold'}}>{imageColumn}</Typography>
                    </FormLabel>
                </Grid>
                <Grid item>
                    <Button onClick={e => uniqAnchor === null ? setUniqAnchor(e.currentTarget) : closeUniq()}
                            endIcon={uniqAnchor === null ? <ArrowDownward/> : <ArrowUpward/>}>
                        Уникальные колонки
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={uniqAnchor}
                        open={Boolean(uniqAnchor)}
                        onClose={closeUniq}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {uniq(headers).map((name, i) => <MenuItem key={name}>
                            <FormControlLabel control={<Checkbox defaultChecked={rowUniqBy.includes(i)}
                                                                 onChange={() => reverseUniqSelection(i)}/>}
                                              label={name}/>
                        </MenuItem>)}
                    </Menu>
                </Grid>
                <Grid item>
                    <Tooltip title={<Typography>Другие уникальные колонки будут игнорироваться, даже если
                        выбраны</Typography>}>
                        <FormControl>
                            <FormControlLabel control={
                                <Checkbox value={uniqByImage} onChange={e => setUniqByImage(e.target.checked)}/>
                            } label='По первым фото'/>
                            <TextField sx={{width: '160px'}} size='small' type='number' min={1} value={uniqImageCount}
                                       onChange={e => setUniqImageCount(e.target.value)}/>
                        </FormControl>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
        <Grid item style={{maxWidth: '1800px'}}>
            <FormControl>
                <FormLabel component="legend">Показывать колонки:</FormLabel>
                <Grid container direction='row'>
                    {uniq(headers).map(it => <Grid item key={it}>
                        <FormControlLabel control={<Switch onChange={() => reverseColumnVisibility(it)}/>} label={it}/>
                    </Grid>)}
                </Grid>
            </FormControl>
        </Grid>
        <Grid item>
            <TableContainer component={Paper}>
                <TablePagination
                    sx={{maxWidth: '90vw'}}
                    rowsPerPageOptions={[10, 25, 50, 100, {value: -1, label: 'All'}]}
                    component="div"
                    count={getFilteredItems().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            {shownColumns.map(it => <TableCell key={it.id}>{it.name}</TableCell>)}
                            <TableCell>Фото</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getPageItems()
                            .map(row => {
                                const cells = []
                                cells.push(...shownColumns.map(it => {
                                    let value = row.cells[it.id]
                                    if (isValidHttpUrl(value)) {
                                        value = <a target='_blank' rel='noreferrer' style={{maxWidth: '150px'}}
                                                   href={value}>{value}</a>
                                    } else {
                                        value = <TextField defaultValue={value} variant='standard'
                                                           onChange={e => row.cells[it.id] = e.target.value
                                                           }/>
                                    }
                                    return <TableCell key={`${row.id}-${it.id}`}>{value}</TableCell>
                                }))
                                cells.push(<TableCell key={`${row.id}-images`}>
                                    <Gallery setRowImages={images => applySiblings(row, images)}
                                             startImages={row.images}/>
                                </TableCell>)
                                return <TableRow key={row.id}>
                                    <TableCell>{row.id}</TableCell>
                                    {cells}
                                </TableRow>
                            })}
                    </TableBody>
                </Table>
                <TablePagination
                    sx={{maxWidth: '90vw'}}
                    rowsPerPageOptions={[10, 25, 50, 100, {value: -1, label: 'All'}]}
                    component="div"
                    count={getFilteredItems().length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </Grid>
        <ScrollToTop/>
    </Grid>
}
export default Main
